// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.bs.js";

var colors_black = Css.hex("000");

var colors_white = Css.hex("fff");

var colors_silver = Css.hex("c0c0c0");

var colors_whitesmoke = Css.hex("f5f5f5");

var colors_firebrick = Css.hex("b22222");

var colors = {
  black: colors_black,
  white: colors_white,
  silver: colors_silver,
  whitesmoke: colors_whitesmoke,
  firebrick: colors_firebrick
};

var primary = colors_black;

var secondary = colors_white;

var muted = colors_silver;

var Color = {
  primary: primary,
  secondary: secondary,
  muted: muted
};

var primary$1 = colors_white;

var secondary$1 = colors_black;

var Background = {
  primary: primary$1,
  secondary: secondary$1
};

var maxWidth = Css.rem(35.0);

var Page = {
  maxWidth: maxWidth
};

var color = colors_white;

var backgroundColor = colors_black;

var Header = {
  color: color,
  backgroundColor: backgroundColor
};

var width = Css.rem(0.2);

var color$1 = colors_silver;

var radius = Css.rem(0.1);

var Thumb = {
  color: color$1,
  radius: radius
};

var Track = {
  color: Css.transparent
};

var Scrollbar = {
  width: width,
  Thumb: Thumb,
  Track: Track
};

var backgroundColor$1 = Css.hex("0d1f2d");

var $$Window = {
  backgroundColor: backgroundColor$1
};

var backgroundColor$2 = Css.hex("e0e8f0");

var height = Css.rem(1.875);

var Topbar = {
  backgroundColor: backgroundColor$2,
  height: height
};

var backgroundColor$3 = Css.hex("ff6057");

var Close = {
  backgroundColor: backgroundColor$3
};

var backgroundColor$4 = Css.hex("ffbd2e");

var Minimize = {
  backgroundColor: backgroundColor$4
};

var backgroundColor$5 = Css.hex("27c93f");

var Maximize = {
  backgroundColor: backgroundColor$5
};

var Controls = {
  Close: Close,
  Minimize: Minimize,
  Maximize: Maximize
};

var color$2 = Css.hex("f4faff");

var maxHeight = Css.rem(25.0);

var Content = {
  color: color$2,
  maxHeight: maxHeight
};

var Terminal = {
  $$Window: $$Window,
  Topbar: Topbar,
  Controls: Controls,
  Content: Content
};

var inline = colors_firebrick;

var block = colors_black;

var Color$1 = {
  inline: inline,
  block: block
};

var backgroundColor$6 = colors_whitesmoke;

var borderColor = colors_silver;

var Code = {
  Color: Color$1,
  backgroundColor: backgroundColor$6,
  borderColor: borderColor
};

var borderColor$1 = colors_whitesmoke;

var Quote = {
  borderColor: borderColor$1
};

var theme = {
  color: {
    primary: Css_AtomicTypes.Color.toString(primary),
    secondary: Css_AtomicTypes.Color.toString(secondary),
    muted: Css_AtomicTypes.Color.toString(muted)
  },
  background: {
    primary: Css_AtomicTypes.Color.toString(primary$1),
    secondary: Css_AtomicTypes.Color.toString(secondary$1)
  },
  page: {
    maxWidth: Css_AtomicTypes.Length.toString(maxWidth)
  },
  scrollbar: {
    width: Css_AtomicTypes.Length.toString(width),
    thumb: {
      color: Css_AtomicTypes.Color.toString(color$1),
      radius: Css_AtomicTypes.Length.toString(radius)
    },
    track: {
      color: Css_AtomicTypes.Color.toString(Css.transparent)
    }
  },
  terminal: {
    window: {
      backgroundColor: Css_AtomicTypes.Color.toString(backgroundColor$1)
    },
    topbar: {
      backgroundColor: Css_AtomicTypes.Color.toString(backgroundColor$2),
      height: Css_AtomicTypes.Length.toString(height)
    },
    controls: {
      close: {
        backgroundColor: Css_AtomicTypes.Color.toString(backgroundColor$3)
      },
      minimize: {
        backgroundColor: Css_AtomicTypes.Color.toString(backgroundColor$4)
      },
      maximize: {
        backgroundColor: Css_AtomicTypes.Color.toString(backgroundColor$5)
      }
    },
    content: {
      color: Css_AtomicTypes.Color.toString(color$2),
      maxHeight: Css_AtomicTypes.Length.toString(maxHeight)
    }
  },
  code: {
    color: {
      inline: Css_AtomicTypes.Color.toString(inline),
      block: Css_AtomicTypes.Color.toString(block)
    },
    background: Css_AtomicTypes.Color.toString(backgroundColor$6),
    border: Css_AtomicTypes.Color.toString(borderColor)
  },
  quote: {
    border: Css_AtomicTypes.Color.toString(borderColor$1)
  }
};

var $$default = theme;

export {
  colors ,
  Color ,
  Background ,
  Page ,
  Header ,
  Scrollbar ,
  Terminal ,
  Code ,
  Quote ,
  theme ,
  $$default ,
  $$default as default,
  
}
/* colors Not a pure module */
